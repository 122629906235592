import { inject, Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { waitForNextHistoryJump } from '@util/functions/events';
import { BehaviorSubject, fromEvent, skip, Subscription, throttleTime } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomMiniCartService {

  private windowRef = inject(WindowRef);

  // eslint-disable-next-line no-magic-numbers
  overviewWidth = 600;
  // eslint-disable-next-line no-magic-numbers
  maxHeight = 600;

  private _open = new BehaviorSubject<boolean>(false);

  mouseTrackingSub: Subscription;

  private nativeMiniCartElement: HTMLElement;

  get open$() {
    return this._open.asObservable();
  }

  toggle() {
    if (this._open.value) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    if (!this._open.value) {
      this._open.next(true);

      this.mouseTrackingSub?.unsubscribe();
      this.mouseTrackingSub = new Subscription(() => {
        this.close();
      });

      this.mouseTrackingSub.add(
        waitForNextHistoryJump(() => {
          this.mouseTrackingSub?.unsubscribe();
        }, this.open$.pipe(skip(1)))
      );

      let mouseWasAtLeastOnceOnMiniCart = false;

      this.mouseTrackingSub.add(
        fromEvent(this.windowRef.nativeWindow, 'mousemove').pipe(throttleTime(250)).subscribe(e => {

          const arr = e.composedPath();
          const isInMiniCart = arr.includes(this.nativeMiniCartElement);

          if (!isInMiniCart && mouseWasAtLeastOnceOnMiniCart) {
            this.mouseTrackingSub?.unsubscribe();
            this.close();
          }
          if (isInMiniCart) {
            mouseWasAtLeastOnceOnMiniCart = true;
          }
        })
      );

    }
  }

  close() {
    if (this._open.value) {
      this._open.next(false);
      this.mouseTrackingSub?.unsubscribe();
    }
  }

  isViewportTooSmallToOpen(): boolean {
    const vp = this.windowRef.nativeWindow.visualViewport;
    return vp.width <= this.overviewWidth || vp.height <= this.maxHeight;
  }

  setNativeMiniCartElement(el: HTMLElement) {
    this.nativeMiniCartElement = el;
  }

}
